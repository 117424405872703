module services {
    export module purchase {
        export class purchaseOrderDetailVatRateService implements interfaces.purchase.IPurchaseOrderDetailVatRateService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            remove(pvrId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "PurchaseOrderDetailVatRate/Delete", {
                    pvrId: pvrId
                });
            }
        }
    }
    angular.module("app").service("purchaseOrderDetailVatRateService", services.purchase.purchaseOrderDetailVatRateService);
}